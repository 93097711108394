import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./Home/Home";
import About from "./About/About";
import Collection from "./Collection/Collection";

const NoMatch = () => <div>No match</div>;

function App() {
  return (
    <div>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>

        <Route exact path="/about">
          <About />
        </Route>

        <Route exact path={`/collection/:collectionName`}>
          <Collection />
        </Route>

        <Route>
          <NoMatch />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
