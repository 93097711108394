import React from "react";
import logo from "./brand.png";
import "./Nav.css";
import { Link } from "react-router-dom";

function Nav(props) {
  const page = props.page;

  return (
    <>
      <header>
        <nav className="navbar navbar-expand-lg navbar-light mt-4">
          <div className="container">
            <a className="navbar-brand" href="/">
              <img src={logo} alt="" height="74.6" width="213.6"></img>
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarText"
              aria-controls="navbarText"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className={"collapse navbar-collapse"} id="navbarText">
              <ul className="navbar-nav mr-auto"></ul>
              <ul className="navbar-nav">
                <li className={`nav-item ${page === "home" ? "active" : ""}`}>
                  <Link to="/" className="nav-link">
                    Photography
                  </Link>
                </li>
                <li className={`nav-item ${page === "about" ? "active" : ""}`}>
                  <Link to="/about" className="nav-link">
                    About + Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Nav;
