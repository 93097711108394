import React, { useEffect } from "react";
import Nav from "../Nav/Nav";
import CollectionGrid from "../CollectionGrid/CollectionGrid";
import Footer from "../Footer/Footer";
import { useParams } from "react-router-dom";

function Collection() {
  const { collectionName } = useParams();

  return (
    <>
      <Nav page="home" />
      <CollectionGrid collection={collectionName} />
      <Footer />
    </>
  );
}

export default Collection;
