// import * as firebase from "firebase/app";
import firebase from "firebase/app";
import "firebase/storage";
import "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA18GXTDW_cDXMr-5fcinn00wqBZJC7Csg",
  authDomain: "aaron-4ed38.firebaseapp.com",
  projectId: "aaron-4ed38",
  storageBucket: "aaron-4ed38.appspot.com",
  messagingSenderId: "939523191577",
  appId: "1:939523191577:web:7ea7b57b89e685a3000b88",
  measurementId: "G-FQPZ9SXB2C",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
// firebase.analytics();

const projectStorage = firebase.storage();
const projectFirestore = firebase.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectStorage, projectFirestore, timestamp };
