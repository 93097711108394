import React from "react";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";
import ThumbnailGrid from "../ThumbnailGrid/ThumbnailGrid";

function Home() {
  return (
    <>
      <Nav page="home" />
      <ThumbnailGrid />
      <Footer />
    </>
  );
}

export default Home;
