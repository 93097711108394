import React from "react";
import useFirestore from "../hooks/useFirestore";
import { Link } from "react-router-dom";

function ThumbnailGrid() {
  const { docs } = useFirestore("thumbnails");

  return (
    <div className="container">
      {docs &&
        docs.map((doc) => (
          <div key={doc.id}>
            <figure className="my-3 text-center">
              <Link to={`/collection/${doc.collection}`}>
                <img
                  src={doc.url}
                  className="img-fluid height-maxed"
                  alt=""
                ></img>
              </Link>
              <figcaption className="lead text-secondary font-italic text-center">
                {doc.title === "" ? doc.collection : doc.title}
              </figcaption>
            </figure>
            <br />
          </div>
        ))}
    </div>
  );
}

export default ThumbnailGrid;
