import React from "react";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";
import profilePic from "./IMG_6184.jpg";

function About() {
  // return <Nav page="about" />;
  return (
    <>
      <Nav page="about" />
      <div className="container">
        <div className="row">
          <div className="col-lg my-4">
            <img
              src={profilePic}
              className="img-fluid height-maxed displayed"
              alt="profile"
            />
          </div>
          <div className="col-lg my-4">
            <div className="jumbotron fill-column height">
              <h1 className="display-4">
                My name is{" "}
                <span className="font-weight-bold">Aaron Navarro</span>
              </h1>
              <p className="lead">
                and I am a Central Coast based photographer with a passion for
                capturing the moment.
              </p>
            </div>
            <div className="card mt-3">
              <div className="card-body">
                <form action="https://formspree.io/f/mnqlgllq" method="post">
                  <div className="mb-3">
                    <label for="email-address" className="form-label">
                      Email address
                    </label>
                    <input
                      type="email"
                      name="_replyto"
                      className="form-control"
                      id="email-address"
                      aria-describedby="emailHelp"
                    ></input>
                  </div>

                  <div className="mb-3">
                    <label for="message" className="form-label">
                      Message
                    </label>
                    <textarea
                      className="form-control"
                      id="message"
                      name="message"
                      rows="3"
                    ></textarea>
                  </div>

                  <div className="mb-3">
                    <input
                      className="btn btn-secondary"
                      type="submit"
                      value="Submit"
                    ></input>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default About;
