import React from "react";
import useFirestore from "../hooks/useFirestore";

function CollectionGrid(props) {
  const { collection } = props;
  const { docs } = useFirestore(collection);

  return (
    <div className="container">
      {docs &&
        docs.map((doc) => (
          <div key={doc.id}>
            <figure className="my-3 text-center">
              <a href={doc.url}>
                <img
                  src={doc.url}
                  className="img-fluid height-maxed"
                  alt=""
                ></img>
              </a>
              <figcaption className="lead text-secondary font-italic text-center"></figcaption>
            </figure>
            <br />
          </div>
        ))}
    </div>
  );
}

export default CollectionGrid;
